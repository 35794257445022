import React from "react"
import Layout from "hoc/Layout"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import { Grid } from "@mui/material"
import Font24 from "components/Fonts/Font24"
import Font16 from "components/Fonts/Font16"
import { companyData } from "constants/companyData"

const Cookies = () => {
  const SITE_NAME = "Cookies"
  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    SITE_NAME,
  ]
  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle={SITE_NAME}>
      <Hr mb={4} mt={6} />
      <Font42 mb={4}>{SITE_NAME}</Font42>
      <Grid container>
        <Grid mb={5}>
          <Font24 mb={3}>{SITE_NAME}</Font24>
          <Font16 mb={2}>
            Niniejsza Polityka dotyczy plików „cookies” i odnosi się do strony
            internetowej https://medira.pl/, której właścicielem jest Firma
            MEDIRA Pflege Sp. z o.o. Sp.k.
          </Font16>
        </Grid>
        <Grid mb={5}>
          <Font24 mb={3}>Czym są pliki “cookies”?</Font24>
          <Font16 mb={2}>
            Pliki “cookies” to małe pliki tekstowe rejestrujące aktywność
            użytkownika online. Ułatwiają poruszanie się po witrynie oraz
            podejmowanie rozmaitych działań, ponieważ rozpoznają urządzenie
            Użytkownika i wyświetlają stronę dopasowaną do jego indywidualnych
            preferencji. Ich wyłączenie może spowodować błędne działanie strony
            oraz brak dostępu do niektórych funkcji.
          </Font16>
        </Grid>
        <Grid mb={5}>
          <Font24 mb={3}>
            Jakiego rodzaju plików “cookie” używamy i w jaki sposób je
            wykorzystujemy?
          </Font24>
          <Font16 mb={2}>
            Stałe pliki cookie Pliki cookie konieczne do przeprowadzenia
            podstawowych czynności w serwisie, takich, jak transakcje i
            umieszczanie ogłoszeń. Pozostają na urządzeniu użytkownika aż do
            momentu ręcznego usunięcia.
          </Font16>
          <Font16 mb={2}>
            Sesyjne pliki cookie Pliki cookie pozwalające zapamiętać wybory
            dokonane przez Użytkownika i używane m.in. przy logowaniu oraz
            wyświetlaniu ostatnio oglądanych ogłoszeń na stronie głównej.
            Pozostają na urządzeniu użytkownika do czasu wylogowania z serwisu
            lub zamknięcia przeglądarki.
          </Font16>
          <Font16 mb={2}>
            Pliki cookie służące do analiz Pliki cookie pozwalające zbierać dane
            związane ze sposobem korzystania z witryny internetowej, w tym
            treści klikane przez użytkownika podczas przeglądania witryny, i
            umożliwiające ulepszanie działania oraz struktury serwisu.
          </Font16>
          <Font16 mb={2}>
            Pliki cookie używane do targetowania Pliki cookie zapamiętujące
            informacje dotyczące korzystania z witryny internetowej,
            umożliwiające proponowanie użytkownikom promocji i innych informacji
            dostosowanych do ich preferencji.
          </Font16>
        </Grid>
        <Grid mb={5}>
          <Font24 mb={3}>Usuwanie plików „cookies”</Font24>
          <Font16 mb={2}>
            Przeglądarki internetowe domyślnie akceptują pliki cookies. W każdej
            chwili można wyłączyć w przeglądarce ich akceptację. Poniżej
            przedstawione są linki do opisanych przez producentów przeglądarek
            sposobów blokowania plików cookies:
          </Font16>
          <Grid>
            <Font16 mb={2} style={{ width: "max-content" }}>
              <a href="https://support.google.com/chrome/answer/95647?hl=pl">
                w przeglądarce Chrome
              </a>
            </Font16>
            <Font16 mb={2}>
              <a href="https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop?redirectslug=W%C5%82%C4%85czanie+i+wy%C5%82%C4%85czanie+obs%C5%82ugi+ciasteczek&redirectlocale=pl">
                w przeglądarce Firefox
              </a>
            </Font16>
            <Font16 mb={2}>
              <a href="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
                w przeglądarce Internet Explorer
              </a>
            </Font16>
            <Font16 mb={2}>
              <a href="https://help.opera.com/en/latest/web-preferences/#cookies">
                w przeglądarce Opera
              </a>
            </Font16>
            <Font16 mb={2}>
              <a href="https://support.apple.com/kb/ph21411?locale=pl_PL">
                w przeglądarce Safari
              </a>
            </Font16>
          </Grid>

          <Font16 mb={2}>
            W razie jakichkolwiek pytań lub problemów prosimy o kontakt z
            administratorem serwisu pod adresem: {companyData.mail}
          </Font16>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Cookies
